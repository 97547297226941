<template>
  <div class="bg-white-200" id="about">
    <h2 class="text-center p-4 title">UN ESPACIO DONDE EL FUEGO CONECTA Y TRANSFORMA</h2>
    <div class="container">
        <div class="row">
            <div class="col-md-9 col-sm-12 col-lg-9 col-xl-9 col-xxl-9 mx-auto">
                <p class="subtitle">
                    Más que un lugar para comer, somos un espacio para reconectar con lo esencial: los sabores,<br>
                    las personas y las emociones que nos alimentan. Nuestro ambiente cálido y acogedor te invita<br>
                    a disfrutar de una experiencia única, donde cada detalle está diseñado para evocar los<br>
                    elementos primordiales de luz, calidez y comunidad.
                </p>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ConnectAndTransformComponent'
}
</script>

<style scoped>
    .bg-white-200 {
        background-color: #ffffff;
        width: 100%;
        height: auto;
    }

    .title {
        font-size: 28px;
        font-weight: 700;
    }

    .subtitle {
        font-size: 20px;
        color: #b84033;
        font-weight: 400;
        text-wrap: balance;
    }
</style>
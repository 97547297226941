<template>
  <div class="menu-bar" id="menu">
    <div class="menu-border"></div>
    <button 
      class="btn menu-button" 
      @click="openPdf"
    >
      VER MENÚ COMPLETO
    </button>
  </div>
</template>

<script>
export default {
  name: "MenuButtonComponent",
  methods: {
    openPdf() {
      const pdfUrl = '/img/menu.pdf';
      window.open(pdfUrl, '_blank');
    }
  }
};
</script>

<style scoped>
.menu-bar {
  background-color: #b00;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 60px;
  width: 100%; 
  position: relative;
  overflow: hidden;
}

.menu-border {
  background-color: #b00;
  position: absolute;
  top: -70px;
  width: 120%;
  height: 40px; 
  border-radius: 0 0 50% 50%;
  z-index: 5;
}

.menu-button {
  background-color: #aa1d17;
  color: #fff;
  font-size: 22px;
  font-weight: 600;
  text-transform: uppercase;
  border: none;
  padding: 10px 30px;
  position: relative;
  z-index: 10;
  border-radius: 20px;
}
</style>
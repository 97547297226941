<template>
  <div class="bg-dark-900 p-3" id="contact">
    <div class="container-fluid">
        <div class="row">
            <div class="col-md-8 col-sm-12 col-lg-8 col-xl-8 col-xxl-8">
                <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3732.908751941841!2d-103.381396!3d20.673291!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjDCsDQwJzIzLjkiTiAxMDPCsDIyJzUzLjAiVw!5e0!3m2!1ses-419!2smx!4v1733890674975!5m2!1ses-419!2smx" width="1000" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                </div>
            </div>

            <div class="col-md-4 col-sm-12 col-lg-4 col-xl-4 col-xxl-4 px-4 py-4 mt-5">
                <div>
                    <img src="../../../public/img/LDF_IconoUbicación.png" alt="" class="img-fluid">
                    <p class="address mt-2">
                        Guadalajara, Mexico
                    </p>
                    <p class="hours">
                        HORARIO:<br>
                        Lunes - Domingo
                    </p>
                </div>

                <div class="d-flex align-content-center">
                    <img src="../../../public/img/LDF_IconoWhats.png" alt="" class="img-fluid">
                    <p class="phone mt-2">
                        <a 
                        href="https://wa.me/523334984360?text=Quiero%20m%C3%A1s%20informaci%C3%B3n%20sobre%20su%20men%C3%BA" 
                        target="_blank" 
                        class="text-decoration-none phone-text">
                        +52 33 3498 4360
                        </a>
                    </p>
                </div>

                <div class="mt-5">
                    <a href="" class="btn btn-primary">HACER UNA <br>RESERVACIÓN</a>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'ContactComponent'
}
</script>

<style scoped>
    .bg-dark-900 {
        background-color: #000000;
        width: 100%;
        height: auto;
    }

    .address {
        color: #f7f7f8;
    }

    .hours {
        color: #f7f7f8;
    }

    .phone {
        color: #f7f7f8;
        font-size: 19px;
        margin-left: 12px;
    }

    .phone-text {
        color: #f7f7f8;
    }

    .btn-primary {
        background-color: #000000 !important;
        border-color: #c02819 !important;
        width: 200px !important;
        font-size: 20px;
        font-weight: 500;
        border-radius: 15px 15px 15px 15px !important;
    }

    .btn-primary:hover {
        background-color: #c02819 !important;
    }
</style>
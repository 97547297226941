<template>
  <div class="bg-footer p-2">
    <p class="copy text-center mt-2">© 2024 Los del fuego. Todos los derechos reservados</p>
  </div>
</template>

<script>
export default {
    name: 'FooterComponent'
}
</script>

<style scoped>
    .bg-footer {
        background-color: #F6F1E3;
        width: 100%;
        height: auto;
    }

    .copy {
        color: #000000;
        font-weight: 300;
    }
</style>
<template>
    <div>
      <div class="hero">
        <div class="container text-center">
          <h2 class="title">NUESTROS SABORES</h2>
  
          <div class="row align-items-center">
            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3">
              <img
                src="../../../public/img/LDF_IconoTaco.png"
                width="90"
                alt="Ícono Taco"
                class="img-fluid icon-left"
              />
            </div>
  
            <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xxl-6">
              <p class="subtitle">
            <span style="font-weight: 900;">Nuestro menú está diseñado para deleitar cada paladar.</span> <br>Desde
                entradas irresistibles hasta postres exquisitos, cada plato es una
                obra maestra creada con ingredientes frescos y de calidad.
              </p>
            </div>
  
            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3">
              <img
                src="../../../public/img/LDF_IconoEmpanada.png"
                width="90"
                alt="Ícono Empanada"
                class="img-fluid icon-right"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "OurFlavorsComponent",
  };
  </script>
  
  <style scoped>
  .hero {
    background-image: url("../../../public/img/LDF_Fondo1.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 40px 0;
  }
  
  .title {
    color: #ffffff;
    font-size: 50px;
    font-weight: 800;
    margin-bottom: 40px;
    text-transform: uppercase;
  }
  
  .subtitle {
    color: #ffffff;
    font-size: 18px;
    font-weight: 400;
    line-height: 1.6;
    text-align: center;
  }
  
  .icon-left,
  .icon-right {
    margin: 20px 0;
  }
</style>
<template>
    <div>
      <div class="container-fluid position-relative">
        <div class="row p-0">
          <div class="col-md-6 col-sm-12 p-0 red-section d-flex justify-content-center align-items-center">
            <img src="../../../public/img/LDF_Frase1.png" alt="Texto" class="img-fluid">
          </div>

          <div class="col-md-6 col-sm-12 p-0">
            <img src="../../../public/img/LDF_Img5.png" alt="Imagen" class="img-fluid">
          </div>
        </div>
  
        <div class="logo-container position-absolute">
          <img src="../../../public/img/LDF_Logo4.png" alt="Logo Central" class="logo-central">
        </div>
      </div>
    </div>
</template>

<script>
export default {
    name: 'SectionAtraidosComponent'
}
</script>

<style scoped>
    .logo-container {
        top: 88%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 10; 
    }

    .logo-central {
        width: 80px;
        height: auto;
    }
</style>
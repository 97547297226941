<template>
    <div>
      <div class="container-fluid p-0">
        <div class="row m-0">
          <div class="col-md-6 col-sm-12 p-5 background-red-col">
            <div class="row">
                <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3">
                    <div style="margin-top: 40px;">
                        <img src="../../../public/img/LDF_Fuego1.png" alt="" width="90" class="img-fluid">
                    </div>
                </div>

                <div class="col-md-9 col-sm-12 col-lg-9 col-xl-9 col-xxl-9">
                    <div>
                        <img src="../../../public/img/LDF_Logo1.png" alt="" class="img-fluid">
                    </div>

                    <div>
                        <p class="mt-4" style="font-weight: 200;">
                            En LOS DEL FUEGO, combinamos pasión,
                            creatividad y los mejores ingredientes para
                            ofrecerte platos que deleitan todos tus
                            sentidos. Nuestro compromiso es brindarte
                            una experiencia inolvidable en cada visita.
                        </p>
                    </div>
                    <div>
                        <img src="../../../public/img/LDF_Linea1.png" alt="" class="img-fluid">
                    </div>
                </div>
            </div>
          </div>
  
          <div class="col-md-3 col-sm-6 p-0">
            <img src="../../../public/img/LDF_Img1.png" alt="Costilla" class="img-fluid h-100 w-100">
          </div>
  
          <div class="col-md-3 col-sm-6 p-0">
            <img src="../../../public/img/LDF_Img2.png" alt="Bebidas" class="img-fluid h-100 w-100">
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: "SectionSaborComponent",
  };
</script>
  
<style scoped>
  .background-red-col {
    background-color: #C02819;
    color: white;
  }
  
  .titulo {
    font-size: 2.5rem;
    font-weight: bold;
    line-height: 1;
    color: white;
  }
  
  .fuego {
    color: #f6f1e3;
  }
  
  .icono-fuego {
    font-size: 1.8rem;
    color: white;
  }
  
  .descripcion {
    font-size: 1.2rem;
    line-height: 1.6;
    font-weight: 400;
    color: #f6f1e3;
  }
  
  .linea-separadora {
    width: 80px;
    height: 4px;
    background-color: #f6f1e3;
  }
  
  img {
    object-fit: cover;
  }
</style>
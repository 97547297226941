<template>
  <div>
    <nav class="navbar navbar-expand-lg bg-body-tertiary p-4 shadow-lg">
        <div class="container">
            <a href="" class="navbar-brand">
                <img src="../../../public/img/LDF_Logo1.png" alt="Logo" class="img-fluid">
            </a>
            <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>

            <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a href="#about" class="nav-link active">NOSOTROS</a>
                    </li>

                    <li class="nav-item">
                        <a href="#menu" class="nav-link">MENU</a>
                    </li>

                    <li class="nav-item">
                        <a href="#contact" class="nav-link">CONTACTO</a>
                    </li>

                    <li class="nav-item">
                        <a href="" class="nav-link social-icon facebook">
                            <i class="fa-brands fa-facebook-f"></i>
                        </a>
                    </li>

                    <li class="nav-item">
                        <a href="" class="nav-link social-icon instagram">
                            <i class="fa-brands fa-instagram"></i>
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    </nav>
  </div>
</template>

<script>
export default {
    name: 'NavbarComponent'
}
</script>

<style scoped>
    .bg-body-tertiary {
        background-color: #000000 !important;
    }

    .nav-link {
        color: #FFFFFF !important;
        font-size: 18px;
        font-weight: 500;
    }

    .social-icon {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #FFFFFF;
        border-radius: 50%;
        width: 40px;
        height: 40px;
        margin-left: 10px;
        color: #000000 !important;
    }

    .social-icon i {
        font-size: 20px;
    }

    .facebook {
        background-color: #FFFFFF;
    }

    .instagram {
        background-color: #FFFFFF;
    }

    .navbar-toggler {
        border: none;
    }

    .navbar-toggler-icon {
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3E%3Cpath stroke='rgba%28255, 255, 255, 1%29' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E");
    }
</style>
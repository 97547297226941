<template>
    <div class="header-component">
      <!-- Video de fondo -->
      <video autoplay muted loop playsinline class="video-background">
        <source src="../../../public/img/Los_del_fuego_header.mp4" type="video/mp4" />
        Tu navegador no soporta videos HTML5.
      </video>
  
      <div class="content-overlay d-flex flex-column align-items-center justify-content-center text-center">
        <div class="logo">
          <img src="../../../public/img/LDF_Logo2.png" alt="Los del Fuego" class="img-fluid" />
        </div>
        
        <p class="description mt-4">
          Descubre una experiencia culinaria única en <br> <strong>LOS DEL FUEGO</strong>. Reservas disponibles ahora.
        </p>
  
        <button class="btn btn-primary mt-3">RESERVA TU MESA AHORA</button>
      </div>
    </div>
</template>
  
<script>
  export default {
    name: "HeaderComponent",
  };
</script>
  
<style scoped>
  .header-component {
    position: relative;
    width: 100%;
    height: 100vh; 
    overflow: hidden;
  }
  
  .video-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: -1;
  }
  
  .content-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    color: white;
    padding: 20px;
  }
  
  .logo img {
    max-width: 150px;
    height: auto;
  }
  
  .description {
    font-size: 1.2rem;
    font-weight: 400;
    color: white;
    max-width: 900px;
  }
  
  .btn-primary {
    background-color: #e74c3c;
    border: none;
    padding: 10px 20px;
    font-size: 1rem;
    font-weight: bold;
    border-radius: 30px;
    text-transform: uppercase;
    box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
    width: 300px !important;
  }
  
  .btn-primary:hover {
    background-color: #C22619;
  }
</style>
  
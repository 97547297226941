<template>
  <div>
    <div class="container-fluid">
        <div class="row p-0">
            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3 p-0">
                <div>
                    <img src="../../../public/img/LDF_Img6.png" alt="" class="img-fluid">
                </div>
            </div>

            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3 p-0">
                <div>
                    <img src="../../../public/img/LDF_Img7.png" alt="" class="img-fluid">
                </div>
            </div>

            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3 p-0">
                <div>
                    <img src="../../../public/img/LDF_Img8.png" alt="" class="img-fluid">
                </div>
            </div>

            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3 p-0">
                <div>
                    <img src="../../../public/img/LDF_Img9.png" alt="" class="img-fluid">
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'SectionImagesHoverComponent'
}
</script>

<style scoped>

</style>
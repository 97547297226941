<template>
  <div id="app">
    <NavbarComponent />
    <HeaderComponent />
    <SectionSaborComponent />
    <SectionTacosComponent />
    <ConnectAndTransformComponent />
    <SectionAtraidosComponent />
    <OurFlavorsComponent />
    <SectionImagesHoverComponent />
    <SectionLineRedComponent />
    <ContactComponent />
    <FooterComponent />
  </div>
</template>

<script>
import NavbarComponent from './components/navbar/NavbarComponent.vue';
import HeaderComponent from './components/header/HeaderComponent.vue';
import SectionSaborComponent from './components/section-sabor/SectionSaborComponent.vue';
import SectionTacosComponent from './components/section-tacos/SectionTacosComponent.vue';
import ConnectAndTransformComponent from './components/connect-and-transform/ConnectAndTransformComponent.vue';
import SectionAtraidosComponent from './components/section-atraidos/SectionAtraidosComponent.vue';
import OurFlavorsComponent from './components/our-flavors/OurFlavorsComponent.vue';
import SectionImagesHoverComponent from './components/section-images-hover/SectionImagesHoverComponent.vue';
import SectionLineRedComponent from './components/section-line-red/SectionLineRedComponent.vue';
import ContactComponent from './components/contact/ContactComponent.vue';
import FooterComponent from './components/footer/FooterComponent.vue';

export default {
  name: 'App',
  components: {
    NavbarComponent,
    HeaderComponent,
    SectionSaborComponent,
    SectionTacosComponent,
    ConnectAndTransformComponent,
    SectionAtraidosComponent,
    OurFlavorsComponent,
    SectionImagesHoverComponent,
    SectionLineRedComponent,
    ContactComponent,
    FooterComponent,
  }
}
</script>

<template>
  <div>
    <div class="container-fluid p-0">
        <div class="row m-0">
            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3 p-0">
                <div>
                    <img src="../../../public/img/LDF_Img3.png" alt="" class="img-fluid">
                </div>
            </div>

            <div class="col-md-3 col-sm-12 col-lg-3 col-xl-3 col-xxl-3 p-0">
                <div>
                    <img src="../../../public/img/LDF_Img4.png" alt="" class="img-fluid">
                </div>
            </div>

            <div class="col-md-6 col-sm-12 col-lg-6 col-xl-6 col-xxl-6 background-red-col">
                <h3 class="title p-4">Inspirados por el fuego, guiados por la pasión</h3>
                <div style="margin-top: -40px;">
                    <p class="subtitle p-4">En LOS DEL FUEGO, creemos que la comida es un acto de
                creación, una chispa que una tradición y modernidad.<br><br>
                Nuestro concepto está profundamente arraigado en la
                simbología del fuego: un elemento que representa la
                energía transformadora, la calidez de la comunidad y la
                renovación constante. Cada platillo es una celebración de
                la vida, de los sabores auténticos y de la conexión humana
                que nos une alrededor de una mesa.”</p>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
    name: 'SectionTacosComponent'
}
</script>

<style scoped>
    .background-red-col {
        background-color: #C02819;
    }

    .title {
        color: #f6f1e3;
        font-size: 19px;
        font-weight: 500;
    }

    .subtitle {
        color: #f6f1e3;
        font-size: 16px;
        font-weight: 300;
    }
</style>